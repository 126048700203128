export const cryptoData = {
  result: [
    {
      id: 'bitcoin',
      icon: 'https://static.coinstats.app/coins/1650455588819.png',
      name: 'Bitcoin',
      symbol: 'BTC',
      rank: 1,
      price: 44870.39834657236,
      priceBtc: 1,
      volume: 35728788775.15447,
      marketCap: 879141227764.5575,
      availableSupply: 19592900,
      totalSupply: 21000000,
      priceChange1h: -0.34,
      priceChange1d: 0.94,
      priceChange1w: 5.02,
      redditUrl: 'https://www.reddit.com/r/Bitcoin/',
      websiteUrl: 'http://www.bitcoin.org',
      twitterUrl: 'https://twitter.com/bitcoin',
      explorers: [
        'https://blockchair.com/bitcoin/',
        'https://btc.com/',
        'https://btc.tokenview.io/',
        'https://www.oklink.com/btc',
        'https://3xpl.com/bitcoin',
        'https://blockchain.coinmarketcap.com/chain/bitcoin',
        'https://blockexplorer.one/btc/mainnet',
      ],
    },
    {
      id: 'ethereum',
      icon: 'https://static.coinstats.app/coins/1650455629727.png',
      name: 'Ethereum',
      symbol: 'ETH',
      rank: 2,
      price: 2262.9329473372445,
      priceBtc: 0.05043730802649077,
      volume: 15049137392.625889,
      marketCap: 271968761037.10645,
      availableSupply: 120184189,
      totalSupply: 120184189,
      priceChange1h: -0.07,
      priceChange1d: 0.42,
      priceChange1w: -1.87,
      redditUrl: 'https://www.reddit.com/r/ethereum',
      websiteUrl: 'https://www.ethereum.org/',
      twitterUrl: 'https://twitter.com/ethereum',
      contractAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      decimals: 18,
      explorers: [
        'https://etherscan.io/',
        'https://ethplorer.io/',
        'https://blockchair.com/ethereum',
        'https://eth.tokenview.io/',
        'https://www.oklink.com/eth',
        'https://3xpl.com/ethereum',
        'https://blockchain.coinmarketcap.com/chain/ethereum',
      ],
    },
    {
      id: 'tether',
      icon: 'https://static.coinstats.app/coins/1650455771843.png',
      name: 'Tether',
      symbol: 'USDT',
      rank: 3,
      price: 0.9958245177734858,
      priceBtc: 0.00002219540265316914,
      volume: 40587650892.897285,
      marketCap: 93233699508.00995,
      availableSupply: 93624627476,
      totalSupply: 93624627476,
      priceChange1h: -0.22,
      priceChange1d: 0.02,
      priceChange1w: -0.2,
      redditUrl: 'https://www.reddit.com',
      websiteUrl: 'https://tether.to/',
      twitterUrl: 'https://twitter.com/Tether_to',
      contractAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      decimals: 18,
      explorers: [
        'https://etherscan.io/token/0xdac17f958d2ee523a2206206994597c13d831ec7',
        'https://ethplorer.io/address/0xdac17f958d2ee523a2206206994597c13d831ec7',
        'https://bscscan.com/token/0x55d398326f99059ff775485246999027b3197955',
        'https://solscan.io/token/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
        'https://polygonscan.com/token/0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        'https://snowtrace.io/token/0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
        'https://evmexplorer.velas.com/token/0xb44a9b6905af7c801311e8f4e76932ee959c663c',
        'https://avascan.info/blockchain/c/address/0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7/token',
        'https://arbiscan.io/token/0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
        'https://blockchair.com/ethereum/erc-20/token/0xdac17f958d2ee523a2206206994597c13d831ec7',
        'https://www.omniexplorer.info/asset/31',
      ],
    },
    {
      id: 'binance-coin',
      icon: 'https://static.coinstats.app/coins/1666608145347.png',
      name: 'BNB',
      symbol: 'BNB',
      rank: 4,
      price: 301.59863198670274,
      priceBtc: 0.006722171383726176,
      volume: 1632019815.3942976,
      marketCap: 46402804362.74094,
      availableSupply: 153856150,
      totalSupply: 153856150,
      priceChange1h: 0.09,
      priceChange1d: -1.98,
      priceChange1w: -2.93,
      redditUrl: 'https://www.reddit.com/r/binance',
      websiteUrl: 'https://www.binance.com',
      twitterUrl: 'https://twitter.com/binance',
      contractAddress: 'BNB',
      decimals: 18,
      explorers: [
        'https://bscscan.com',
        'https://explorer.binance.org/',
        'https://binance.mintscan.io/',
        'https://etherscan.io/token/0xb8c77482e45f1f44de1745f52c74426c631bdd52',
        'https://ethplorer.io/address/0xb8c77482e45f1f44de1745f52c74426c631bdd52',
        'https://www.oklink.com/bsc',
        'https://3xpl.com/bnb',
        'https://explorer.energi.network/token/0xc3c19ee91cf3c1f7fbf3716a09d21dc35de0bd6d',
        'https://etherscan.io/token/0xB8c77482e45F1F44dE1745F52C74426C631bDD52',
      ],
    },
    {
      id: 'solana',
      icon: 'https://static.coinstats.app/coins/1701234596791.png',
      name: 'Solana',
      symbol: 'SOL',
      rank: 5,
      price: 93.7035752645126,
      priceBtc: 0.0020885091157300402,
      volume: 3591253070.807396,
      marketCap: 40461063524.96437,
      availableSupply: 431798503,
      totalSupply: 566552781,
      priceChange1h: -0.98,
      priceChange1d: -2.94,
      priceChange1w: -11.17,
      redditUrl: 'https://www.reddit.com/r/solana',
      websiteUrl: 'https://solana.com/',
      twitterUrl: 'https://twitter.com/solana',
      contractAddress: '0x7dff46370e9ea5f0bad3c4e29711ad50062ea7a4',
      decimals: 18,
      explorers: [
        'https://solscan.io/',
        'https://explorer.solana.com/',
        'https://solanabeach.io/',
        'https://solana.fm/',
        'https://www.oklink.com/sol',
      ],
    },
    {
      id: 'ripple',
      icon: 'https://static.coinstats.app/coins/XRPdnqGJ.png',
      name: 'XRP',
      symbol: 'XRP',
      rank: 6,
      price: 0.5629434018448091,
      priceBtc: 0.000012547145859419824,
      volume: 1103177305.849332,
      marketCap: 30517408090.486538,
      availableSupply: 54210437480,
      totalSupply: 99988065643,
      priceChange1h: -0.6,
      priceChange1d: -1.22,
      priceChange1w: -9.34,
      redditUrl: 'https://www.reddit.com/r/ripple',
      websiteUrl: 'https://ripple.com/currency/',
      twitterUrl: 'https://twitter.com/Ripple',
      contractAddress: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
      decimals: 18,
      explorers: [
        'https://blockchair.com/ripple',
        'https://xrpcharts.ripple.com',
        'https://xrpscan.com/',
        'https://bithomp.com/explorer/',
        'https://xrpcharts.ripple.com/#/graph/',
      ],
    },
    {
      id: 'usd-coin',
      icon: 'https://static.coinstats.app/coins/1650455825065.png',
      name: 'USDC',
      symbol: 'USDC',
      rank: 7,
      price: 1,
      priceBtc: 0.000022259755933599935,
      volume: 8964820403,
      marketCap: 25343872213,
      availableSupply: 25341702291,
      totalSupply: 25346562356,
      priceChange1h: 0.02,
      priceChange1d: -0.05,
      priceChange1w: -0.03,
      redditUrl: 'https://www.reddit.com',
      websiteUrl: 'https://www.circle.com/en/usdc',
      twitterUrl: 'https://twitter.com/circle',
      contractAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      decimals: 18,
      explorers: [
        'https://etherscan.io/token/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        'https://stepscan.io/token/0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d',
        'https://nearblocks.io/token/17208628f84f5d6ad33f0da3bbbeb27ffcb398eac501a31bd6ad2011e36133a1',
        'https://ethplorer.io/address/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        'https://basescan.org/token/0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
        'https://arbiscan.io/token/0xaf88d065e77c8cc2239327c5edb3a432268e5831',
        'https://hashscan.io/mainnet/token/0x000000000000000000000000000000000006f89a',
        'https://bscscan.com/token/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        'https://www.oklink.com/en/okc/token/0xc946daf81b08146b1c7a8da2a851ddf2b3eaaf85',
        'https://www.teloscan.io/token/0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b',
      ],
    },
    {
      id: 'staked-ether',
      icon: 'https://static.coinstats.app/coins/staked-etheruqt.png',
      name: 'Lido Staked Ether',
      symbol: 'STETH',
      rank: 8,
      price: 2263.48,
      priceBtc: 0.05038451236058478,
      volume: 12583680,
      marketCap: 20949523852,
      availableSupply: 9253636,
      totalSupply: 9253636,
      priceChange1h: -0.06,
      priceChange1d: 0.54,
      priceChange1w: -1.67,
      redditUrl: 'https://www.reddit.com/r/lidofinance/',
      websiteUrl: 'https://www.lido.fi',
      twitterUrl: 'https://twitter.com/lidofinance',
      contractAddress: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
      decimals: 18,
      explorers: [
        'https://etherscan.io/token/0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
        'https://ethplorer.io/address/0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
        'https://ethereum.dex.guru/token/0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
      ],
    },
    {
      id: 'cardano',
      icon: 'https://static.coinstats.app/coins/CardanojXddT.png',
      name: 'Cardano',
      symbol: 'ADA',
      rank: 9,
      price: 0.5071342431985936,
      priceBtc: 0.00001130324522654837,
      volume: 519686825.9246739,
      marketCap: 17781371963.996098,
      availableSupply: 35062455755,
      totalSupply: 45000000000,
      priceChange1h: -0.59,
      priceChange1d: -3.31,
      priceChange1w: -16.06,
      redditUrl: 'https://www.reddit.com/r/cardano',
      websiteUrl: 'https://www.cardano.org/en/home/',
      twitterUrl: 'https://twitter.com/Cardano_CF',
      contractAddress: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
      decimals: 18,
      explorers: [
        'https://cardanoscan.io/',
        'https://cardanoexplorer.com/',
        'https://blockchair.com/cardano',
        'https://adaex.org/',
        'https://adastat.net/',
        'https://ada.tokenview.io/',
        'https://3xpl.com/cardano',
      ],
    },
    {
      id: 'avalanche-2',
      icon: 'https://static.coinstats.app/coins/1675667082696.png',
      name: 'Avalanche',
      symbol: 'AVAX',
      rank: 10,
      price: 34.14,
      priceBtc: 0.0007599480675731017,
      volume: 875720471,
      marketCap: 12502585918,
      availableSupply: 366597364,
      totalSupply: 434630281,
      priceChange1h: -0.37,
      priceChange1d: -4,
      priceChange1w: -13.77,
      redditUrl: 'https://www.reddit.com/r/Avax',
      websiteUrl: 'https://www.avax.network',
      twitterUrl: 'https://twitter.com/avax',
      contractAddress: '0xd26649b3eb22eb275326a8cb052d2f4736c863cf',
      decimals: 18,
      explorers: [
        'https://snowtrace.io/',
        'https://explorer.avax.network/',
        'https://avascan.info/',
        'https://www.oklink.com/avax',
        'http://avascan.info/',
      ],
    },
    {
      id: 'dogecoin',
      icon: 'https://static.coinstats.app/coins/DogecoinIZai5.png',
      name: 'Dogecoin',
      symbol: 'DOGE',
      rank: 11,
      price: 0.0785992655070039,
      priceBtc: 0.0000017518571947513752,
      volume: 507579314.8695062,
      marketCap: 11205796392.058752,
      availableSupply: 142568716384,
      totalSupply: 142568886384,
      priceChange1h: -0.27,
      priceChange1d: -2.49,
      priceChange1w: -13.21,
      redditUrl: 'https://www.reddit.com/r/dogecoin/',
      websiteUrl: 'http://dogecoin.com/',
      twitterUrl: 'https://twitter.com/dogecoin',
      contractAddress: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
      decimals: 18,
      explorers: [
        'https://blockchair.com/dogecoin',
        'https://doge.tokenview.io/',
        'http://dogechain.info/chain/Dogecoin',
        'https://3xpl.com/dogecoin',
      ],
    },
    {
      id: 'polkadot',
      icon: 'https://static.coinstats.app/coins/1641284295533.png',
      name: 'Polkadot',
      symbol: 'DOT',
      rank: 12,
      price: 7.151312849814387,
      priceBtc: 0.00015939180585280315,
      volume: 258441989.1307376,
      marketCap: 9414876221.163532,
      availableSupply: 1316524171,
      totalSupply: 1397470962,
      priceChange1h: -0.46,
      priceChange1d: -1.52,
      priceChange1w: -13.6,
      redditUrl: 'https://www.reddit.com/r/dot/',
      websiteUrl: 'https://polkadot.network/',
      twitterUrl: 'https://twitter.com/Polkadot',
      contractAddress: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
      decimals: 18,
      explorers: [
        'http://polkascan.io/',
        'https://polkadot.subscan.io/',
        'https://hubble.figment.io/polkadot/chains/polkadot',
        'https://3xpl.com/polkadot',
        'https://www.mintscan.io/secret/address/secret1h5d3555tz37crrgl5rppu2np2fhaugq3q8yvv9',
      ],
    },
    {
      id: 'tron',
      icon: 'https://static.coinstats.app/coins/TRONxJljY.png',
      name: 'TRON',
      symbol: 'TRX',
      rank: 13,
      price: 0.10315378072933472,
      priceBtc: 0.0000022991397155026576,
      volume: 110666037.02485974,
      marketCap: 9106280268.953682,
      availableSupply: 88278686487,
      totalSupply: 88278902009,
      priceChange1h: -0.52,
      priceChange1d: -0.91,
      priceChange1w: -3.74,
      redditUrl: 'https://www.reddit.com/r/Tronix',
      websiteUrl: 'https://tron.network',
      twitterUrl: 'https://twitter.com/trondao',
      contractAddress: '0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
      decimals: 18,
      explorers: [
        'https://tronscan.org/',
        'https://trx.tokenview.io',
        'https://www.oklink.com/trx',
        'https://tronscan.org/#/',
        'https://www.trxplorer.io/',
      ],
    },
    {
      id: 'chainlink',
      icon: 'https://static.coinstats.app/coins/ChainLink0JkIR.png',
      name: 'Chainlink',
      symbol: 'LINK',
      rank: 14,
      price: 13.586276299994513,
      priceBtc: 0.0003028172812111658,
      volume: 360987292.1439634,
      marketCap: 7718363172.02487,
      availableSupply: 568099971,
      totalSupply: 1000000000,
      priceChange1h: -0.99,
      priceChange1d: -0.35,
      priceChange1w: -11.07,
      redditUrl: 'https://www.reddit.com/r/Chainlink/',
      websiteUrl: 'https://chain.link/',
      twitterUrl: 'https://twitter.com/chainlink',
      contractAddress: '0x514910771af9ca656af840dff83e8264ecf986ca',
      decimals: 18,
      explorers: [
        'https://etherscan.io/token/0x514910771af9ca656af840dff83e8264ecf986ca',
        'https://ethplorer.io/address/0x514910771af9ca656af840dff83e8264ecf986ca',
        'https://arbiscan.io/token/0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
        'https://explorer.chain.link',
        'https://blockscout.com/poa/xdai/tokens/0xE2e73A1c69ecF83F464EFCE6A5be353a37cA09b2/token-transfers',
        'https://bscscan.com/token/0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
        'https://polygonscan.com/token/0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
        'https://snowtrace.io/token/0x5947bb275c521040051d82396192181b413227a3',
        'https://nearblocks.io/token/514910771af9ca656af840dff83e8264ecf986ca.factory.bridge.near',
        'https://scan.meter.io/address/0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8',
      ],
    },
    {
      id: 'matic-network',
      icon: 'https://static.coinstats.app/coins/1686037797025.png',
      name: 'Polygon',
      symbol: 'MATIC',
      rank: 15,
      price: 0.7939569842755763,
      priceBtc: 0.000017696084642194666,
      volume: 441938492.27362126,
      marketCap: 7370257878.861724,
      availableSupply: 9282943566,
      totalSupply: 10000000000,
      priceChange1h: -0.36,
      priceChange1d: -5.76,
      priceChange1w: -19.86,
      redditUrl: 'https://www.reddit.com/r/maticnetwork/',
      websiteUrl: 'https://polygon.technology/',
      twitterUrl: 'https://twitter.com/0xPolygon',
      contractAddress: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
      decimals: 18,
      explorers: [
        'https://etherscan.io/token/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
        'https://ethplorer.io/address/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
        'https://polygonscan.com/token/0x0000000000000000000000000000000000001010',
        'https://bscscan.com/token/0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
        'https://moonriver.moonscan.io/token/0x682f81e57eaa716504090c3ecba8595fb54561d8',
        'https://moonbeam.moonscan.io/token/0x3405A1bd46B85c5C029483FbECf2F3E611026e45',
        'https://explorer.energi.network/token/0x98997e1651919faeacee7b96afbb3dfd96cb6036',
        'https://www.oklink.com/polygon',
        'https://3xpl.com/polygon',
        'https://binplorer.com/address/0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
      ],
    },
    {
      id: 'wrapped-bitcoin',
      icon: 'https://static.coinstats.app/coins/wrapped-bitcoinoc1.png',
      name: 'Wrapped Bitcoin',
      symbol: 'WBTC',
      rank: 16,
      price: 44950,
      priceBtc: 1.000576029215317,
      volume: 282639713,
      marketCap: 7109744028,
      availableSupply: 158169,
      totalSupply: 158169,
      priceChange1h: -0.13,
      priceChange1d: 1.21,
      priceChange1w: 5.34,
      redditUrl: 'https://www.reddit.com',
      websiteUrl: 'https://www.wbtc.network/',
      twitterUrl: 'https://twitter.com/WrappedBTC',
      contractAddress: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      decimals: 18,
      explorers: [
        'https://etherscan.io/token/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
        'https://ethplorer.io/address/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
        'https://blockscout.com/poa/xdai/tokens/0x8e5bBbb09Ed1ebdE8674Cda39A0c169401db4252/token-transfers',
        'https://scan.tomochain.com/tokens/0x503b2ddc059b81788fd1239561596614b27faade',
        'https://polygonscan.com/token/0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
        'https://nearblocks.io/address/2260fac5e5542a773aa44fbcfedf7c193bc2c599.factory.bridge.near#transaction',
        'https://cronos-explorer.crypto.org/token/0x062e66477faf219f25d27dced647bf57c3107d52',
        'https://snowtrace.io/token/0x50b7545627a5162f82a992c33b87adc75187b218',
        'https://explorer.mainnet.aurora.dev/token/0xF4eB217Ba2454613b15dBdea6e5f22276410e89e',
        'https://moonriver.moonscan.io/token/0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8',
      ],
    },
    {
      id: 'internet-computer',
      icon: 'https://static.coinstats.app/coins/internet-computer4kw.png',
      name: 'Internet Computer',
      symbol: 'ICP',
      rank: 17,
      price: 13.06,
      priceBtc: 0.0002907124124928151,
      volume: 313146822,
      marketCap: 5945084250,
      availableSupply: 454980478,
      totalSupply: 512797541,
      priceChange1h: 1.1,
      priceChange1d: 8.15,
      priceChange1w: -2.21,
      redditUrl: 'https://www.reddit.com/r/dfinity/',
      websiteUrl: 'https://internetcomputer.org/',
      twitterUrl: 'https://twitter.com/dfinity',
      contractAddress: 'ryjl3-tyaaa-aaaaa-aaaba-cai',
      decimals: 18,
      explorers: [
        'https://dashboard.internetcomputer.org/canister/ryjl3-tyaaa-aaaaa-aaaba-cai',
      ],
    },
    {
      id: 'shiba-inu',
      icon: 'https://static.coinstats.app/coins/1646234478930.png',
      name: 'Shiba Inu',
      symbol: 'SHIB',
      rank: 18,
      price: 0.0000092,
      priceBtc: 2.047897545891194e-10,
      volume: 202179835,
      marketCap: 5426691358,
      availableSupply: 589291143245961,
      totalSupply: 999982388745437,
      priceChange1h: 0.03,
      priceChange1d: -3.5,
      priceChange1w: -11.99,
      redditUrl: 'https://www.reddit.com/r/SHIBArmy/',
      websiteUrl: 'https://shibatoken.com/',
      twitterUrl: 'https://twitter.com/Shibtoken',
      contractAddress: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
      decimals: 18,
      explorers: [
        'https://etherscan.io/token/0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
        'https://ethplorer.io/address/0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
        'https://bscscan.com/token/0x2859e4544c4bb03966803b044a93563bd2d0dd4d',
        'https://explorer.energi.network/token/0x7fDb933327aa6989ae706001c2EA54BA5E046e79',
        'https://ethereum.dex.guru/token/0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
      ],
    },
    {
      id: 'dai',
      icon: 'https://static.coinstats.app/coins/1579614462667.png',
      name: 'Dai',
      symbol: 'DAI',
      rank: 19,
      price: 0.998881,
      priceBtc: 0.000022234847266710236,
      volume: 199837010,
      marketCap: 5283706975,
      availableSupply: 5289625031,
      totalSupply: 5289625031,
      priceChange1h: -0.14,
      priceChange1d: 0.05,
      priceChange1w: 0,
      redditUrl: 'https://www.reddit.com/r/MakerDAO',
      websiteUrl: 'https://makerdao.com/',
      twitterUrl: 'https://twitter.com/MakerDAO',
      contractAddress: '0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359',
      decimals: 18,
      explorers: [
        'https://etherscan.io/token/0x6b175474e89094c44da98b954eedeac495271d0f',
        'https://ethplorer.io/address/0x6b175474e89094c44da98b954eedeac495271d0f',
        'https://blockchair.com/ethereum/erc-20/token/0x6b175474e89094c44da98b954eedeac495271d0f',
        'https://arbiscan.io/token/0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
        'https://polygonscan.com/token/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
        'https://bscscan.com/token/0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        'https://snowtrace.io/token/0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
        'https://avascan.info/blockchain/c/address/0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
        'https://explorer.mainnet.aurora.dev/token/0xe3520349f477a5f6eb06107066048508498a291b/token-transfers',
        'https://moonriver.moonscan.io/token/0x80a16016cc4a2e6a2caca8a4a498b1699ff0f844',
        'https://etherscan.io/token/0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359',
        'https://ethplorer.io/address/0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359',
      ],
    },
    {
      id: 'litecoin',
      icon: 'https://static.coinstats.app/coins/LitecoinGiD2Q.png',
      name: 'Litecoin',
      symbol: 'LTC',
      rank: 20,
      price: 65.21315329006374,
      priceBtc: 0.001453501264250902,
      volume: 415039317.62892365,
      marketCap: 4830332264.584919,
      availableSupply: 74069908,
      totalSupply: 84000000,
      priceChange1h: -0.01,
      priceChange1d: -0.67,
      priceChange1w: -11.42,
      redditUrl: 'https://www.reddit.com/r/litecoin/',
      websiteUrl: 'http://litecoin.org',
      twitterUrl: 'https://twitter.com/LTCFoundation',
      contractAddress: '0x21a5230038c8c849d2ffb0d3ce762228ed245237',
      decimals: 18,
      explorers: [
        'https://blockchair.com/litecoin',
        'https://chainz.cryptoid.info/ltc/',
        'https://bitupper.com/en/explorer/litecoin',
        'https://litecoinblockexplorer.net/',
        'https://ltc.tokenview.io/',
        'https://explorer.energi.network/token/0x21a5230038c8c849d2ffb0d3ce762228ed245237',
        'https://www.oklink.com/ltc',
        'https://3xpl.com/litecoin',
        'http://explorer.litecoin.net/chain/Litecoin',
      ],
    },
  ],
  meta: {
    page: 1,
    limit: 20,
    itemCount: 17965,
    pageCount: 899,
    hasPreviousPage: false,
    hasNextPage: true,
  },
}

export const cryptoAssets = [
  {
    id: 'bitcoin',
    amount: 0.02,
    price: 26244,
    date: new Date(),
  },
  {
    id: 'ethereum',
    amount: 5,
    price: 2400,
    date: new Date(),
  },
  {
    id: 'litecoin',
    amount: 24,
    price: 60.374,
    date: new Date(),
  }
  
]
