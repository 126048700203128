import React, { useContext, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Context from '../store/context';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function AppChart(){    
  const {assets} = useContext(Context)

  const data = {
      labels: assets.map(asset=>asset.id),
      datasets: [
        {
          label: '$',
          data: assets.map(asset=>asset.totalAmount),
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
  }
  return(
      <>
      <div style={{height: 400, marginBottom: '1rem', display: 'flex'}}>
          <Pie data={data} />
      </div>
      
      </>
  )
}